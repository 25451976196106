.avatar {
  &-img {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: 1.25rem;
  }
  &-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}