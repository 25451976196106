.page-home {

  .home-header {
    background-image: url('../../assets/images/home-banner.jpg');
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    color: $white;
  }

  .home-main {
    background-color: $gray-baby;
  }

  .home-button {
    width: 170px;
  }
}