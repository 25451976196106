@import './bootstrap/imports';

@import './util';
@import './core';
@import './layout';
@import './page';
@import './progress.scss';

@import './components/button';
@import './components/navbar';
@import './components/sidebar';
@import './components/main';
@import './components/modal';
@import './components/form';
@import './components/toast';
@import './components/spinner';
@import './components/dropdown';
@import './components/avatar';
@import './components/alert';
@import './components/table';
@import './components/select';
@import './components/search';
@import './components/empty-state';
@import './components/file-upload';
@import './components/image-upload';
@import './components/tabs';
@import './components/switch';
@import './components/company-detail';
@import './components/lab-contact';
@import './components/notification.scss';
@import './components/spinner2.scss';
@import './components/public-header.scss';
@import './components/public-footer.scss';
@import './components/dealer-list-view.scss';

@import './pages/tabs';
@import './pages/home';
@import './pages/clients';
@import './pages/orders';
@import './pages/component';
@import './pages/messages';
@import './pages/account';
@import './pages/users';
@import './pages/download-app';
@import './pages/landing-page';
@import './pages/home-lab';
@import './pages/client';
@import './pages/contract-type.scss';
@import './pages/Manager/OrdersView.scss';
@import './pages/public-pages.scss';
@import './pages/upcoming-invoice.scss';
@import './pages/privacy-documents.scss';
@import './pages/license-content.scss';
@import './pages/Manager/dealerView.scss';