.public-header {
    @media only screen and (min-width: 1200px) {
        &-logo {
            margin-left: 110px;
        }
    }
    
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &-logo {
            margin-left: -3px;
        }
    }
    
    @media only screen and (min-width: 768px) and (max-width: 991px){
        &-logo {
            margin-left: -70px
        }
    }
    
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        &-logo {
            margin-left: -123px;
        }
    }
}
