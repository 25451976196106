.content-container {
    margin: 0;
    height: calc(100vh - 222px) !important;
}

.public-page {
    width: 100vw;
    height: 100%;
    background: url('../../assets/images/bg_network.jpg') center/cover no-repeat;
    display: flex;

    &-text {
        text-align: justify;
    }

    @media only screen and (min-width: 1200px) {
        &-col {
            margin: 0 285px;
            padding: 0;
        }

    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &-col {
            margin: 0 170px;
            padding: 0;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        &-col {
            margin: 0 100px;
            padding: 0;
        }
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
        &-col {
            margin: 0 50px;
            padding: 0;
        }
    }
}

.table-license-content {
    background-color: $white;
    thead {
      th {
        border-bottom: 1px solid $gray-line;
        font-size: 0.75rem;
      }
    }
    tbody {
        td {
          background-color: $white;
          cursor: pointer;
          font-size: 0.75rem;
        }
      }
}