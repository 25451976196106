.select-wrapper {
  width: 390px;
}

.select-container {
  .select__control {
    min-height: 56px;
    border-color: rgb(212, 206, 206);
    border-radius: 0.25rem;
    &:hover {
      border-color: rgb(212, 206, 206);
    }
    &--is-focused {
      box-shadow: none;
    }
  }
  &.sm {
    .select__control {
      min-height: 48px;
    }
  }

  .select__value-container {
    padding: 2px 0.5rem 2px 1rem;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    box-shadow: 0px 4px 8px rgba(158, 28, 28, 0.05);
    border-radius: 0.25rem;
    .select__menu-list {
      padding: 0.5rem 0;
    }
    .select__option {
      &--is-focused {
        color: rgb(233, 111, 66);
        background-color: rgb(246, 246, 249);
      }
      &--is-selected {
        color: gray;
        background-color: white;
      }
    }
  }
}

.CalendarDay__selected_span {
  background: #f2b456 !important;
  color: white; //text
  border: 1px solid #cfccc7 !important;
}

.CalendarDay__selected {
  background: #fa9600 !important;
  color: white !important;
}

.CalendarDay__selected:hover {
  background: #f2b456 !important;
  color: white !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #f2b456 !important;
}

.reset-button {
  height: 47px;
  width: 80px;
}

.table-components {
  background-color: #F7F8F9;
  thead {
    th {
      border-bottom: 1px solid #C7CED8;
    }
  }
}

.table-expanded-row {
  cursor: pointer;
  td {
    background-color: #F7F8F9;
  }
}