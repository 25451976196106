.notification-btn-container {
    background-color: white;
    margin-right: 10px;

    &:focus {
        background-color: white;
    }

    &:hover {
        background-color: white;
    }
}

.notification-list {
    max-height: 500px;
    overflow-y: auto;
}

.notification-item {
    display: flex;
    align-items: center;

    &-unread {
        background-color: aliceblue;
    }
}

.list-group-item {
    cursor: pointer;
}

.mark-as-unread {
    padding: 0px;
}

.notification-circle {
    border-radius: 50%;
    border: 5px solid $primary;
    flex-grow: 1;
    width: 10px;
    height: 10px;
}

.notification-item-message {
    flex-grow: 9;
    margin-right: 10px;
}

.notification {
    position: relative;
}

.notification-bubble {
    position: absolute;
    top: -20px;
    right: -10px;
}

.notification-btn-container {
    border: 1px solid $primary;
}