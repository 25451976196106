// Bootstrap

.form-group {
  position: relative;
  svg {
    position: absolute;
    right: 12px;
    top: rem(49px);
  }
}

.form-control {
  border: 1px solid $white;
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: rem(17px);

  &.is-invalid {
    background-image: none;
    border-color: $red;
  }
}

.form-label {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: rem(13px);
  line-height: 2rem;
  letter-spacing: rem(1px);
  text-transform: uppercase;
  color: $gray;
}

.custom-control {
  font-size: rem(14px);
  color: $gray;
  line-height: 1.4;
  &-label {
    &::before {
      top: 0;
      width: rem(17px);
      height: rem(17px);
      border: $gray solid 2px;
    }
    &::after {
      top: 0;
      width: rem(17px);
      height: rem(17px);
    }
  }
}


// Custom
.form-link {
  font-size: rem(15px);
  color: $gray;
  a {
    color: $gray;
    text-decoration: underline;
  }
}

.form-info {
  display: flex;
  &-icon {
    margin-right: 0.5rem;
  }
  &-text {
    color: $gray;
    font-size: rem(14px);
    line-height: 1.4285714286;
  }
}