.btn {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: rem(2px);
  &-sm {
    letter-spacing: rem(1px);
  }
  &-primary {
    color: $white;
  }
  &:disabled {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $white;
  }
}

.btn-outline {
  &-primary {
    background-color: $white;
    color: $gray-800;
    &:hover {
      color: $white;
    }
  }
}

.btn-download {
  background-color: transparent;
  color: $primary;
  &:hover {
    color: $white;
  }
}

.btn-icon {
  margin-right: 0.5rem;
}

.btn-full {
  width: 100%;
}

.btn-back {
  color: $gray-dark;
  svg {
    margin-right: 0.5rem;
  }
  span  {
    text-transform: uppercase;
  }
  &:hover {
    color: $gray-dark;
    text-decoration: none;
  }
}