.lab-navbar,
.mill-selection {
  img {
    max-height: 40px;
  }
}

.page-home-lab {
  .page-container {
    padding-top: 60px;
    width: 100%;
    @include media-breakpoint-up(md) {
      padding-top: 190px;
    }
  }

  .spinner {
    height: calc(100vh - 72px);
  }
}
