.modal-content {
  background-color: $gray-100;
  border: none;
}

.modal-header {
  position: relative;
  border-bottom: none;

  .modal-title {
    padding: 3.75rem 0 2.25rem 0;
    text-align: center;
    width: 100%;
    font-family: $font-family-sans-serif;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('../../assets/images/close.svg');
    > span {
      display: none;
    }
  }
}

.modal-body {
  padding: 0 3.75rem 3.75rem;
}

.modal-activation {
  max-width: 600px;
  .btn {
    width: 180px;
  }
}
