.page-messages {
  .select-wrapper {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }
}

.word-break {
  overflow-wrap: break-word;
}

.messages-list {
  background-color: $white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  &-item {
    border-bottom: 1px solid $gray-line;
    &:hover {
      background-color: $gray-sky;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .link {
      text-decoration: none;
      color: inherit;
      display: block;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    .image {
      width: 80px;
      height: 80px;
      img {
        border-radius: 50%;
      }
    }

    .heading {
      width: 200px;
    }

    .content {
      .intro {
        color: $gray;
        font-size: rem(15px);
        line-height: rem(23px);
      }
      .message {
        line-height: rem(28px);
      }
    }

    &.unread {
      .content {
        .intro {
          color: $green;
        }
        .message {
          font-weight: 800;
        }
      }
    }
  }
}