.header {
  position: fixed;
  width: 100%;
  height: 72px;
  background-color: white;
  z-index: 1;

  svg {
    max-height: 40px;
  }
}

.app-icon {
  max-height: 24px;
  height: 24px;
}

.main {
  padding-top: 72px;
  flex: 1;
}

.footer {
  padding: 22px;

  svg {
    max-height: 40px;
  }
}

.full-vw {
  width: 100vw;
}

.manager-main {
  padding: 100px;
  flex: 1
}