.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $body-color;
    }
  }
}

.navbar-expand-md {
  .navbar-nav {
    .nav-link {
      padding: 10px 20px;
    }
  }
}

.navbar-footer {
  padding-left: 0;
  padding-right: 0;

  .navbar-nav {
    .nav-link {
      padding: 0 10px;
    }
  }
}

.user-navbar {
  border-bottom: 1px solid $gray-line;
}

.app-version {
  position: absolute;
  top: 95%;
  font-size: 13px;

  &-mill {
    left: 57%;
  }
}


.user-navbar-brand {
  margin-right: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    max-height: 40px;
  }
}

.blocked-mill {
  width: 30px;
}