.page-tabs {
  background-color: $gray-sky;
  .page-header {
    background-color: $gray-baby;
  }

  .nav-tabs {
    margin-top: -50px;
  }

  .tab-content {
    padding: 4rem 0;
    overflow: hidden;
  }
}