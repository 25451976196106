.file-upload {
  &-input {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $white;
    border-radius: $border-radius;
    height: 240px;

    &.hide {
      display: none;
    }
  }
  &-files {
    min-height: 240px;
    li {
      display: flex;
      justify-content: space-between;
      background-image: url('../../assets/images/document.svg');
      background-position: left center;
      background-repeat: no-repeat;
      padding: 1rem 0 1rem 3rem;
      .file-upload-delete {
        svg {
          position: inherit;
          path {
            fill: $gray-bright;
          }
        }
        &:hover {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}