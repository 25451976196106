.page-orders {
  .select-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  .width-auto {
    width: auto;
  }

  .download-application {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(150px);

    img {
      width: 72px;
      height: 72px;
    }

    a {
      color: #99A6B2;
    }
  }
}