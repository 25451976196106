.empty-state {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #99A6B2;

  &-title {
    font-weight: 600;
    font-size: rem(21px);
    line-height: rem(27px);
  }
}