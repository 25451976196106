.select-container {
  .select__control {
    min-height: 56px;
    border-color: $white;
    border-radius: $border-radius;
    &:hover {
      border-color: $white;
    }
    &--is-focused {
      box-shadow: none;
    }
  }
  &.sm {
    .select__control {
      min-height: 48px;
    }
  }

  .select__value-container {
    padding: 2px 0.5rem 2px 1rem;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .05);
    border-radius: $border-radius;
    .select__menu-list {
      padding: 0.5rem 0;
    }
    .select__option {
      &--is-focused {
        color: $primary;
        background-color: $gray-100;
      }
      &--is-selected {
        color: $gray;
        background-color: $white;
      }
    }
  }
}