.table-actions {
  visibility: hidden;
  text-align: right;
  a {
    color: $gray;
    text-transform: uppercase;
    text-decoration: none;
    font-size: rem(13px);
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0 1rem;
  }
}

.table {
  thead {
    th {
      border: none;
      padding-bottom: 0.5rem;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 2rem;
      color: $table-head-color;
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
          &:first-child {
            border-top-left-radius: .625rem;
          }
          &:last-child {
            border-top-right-radius: .625rem;
          }
        }
      }
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: .625rem;
          }
          &:last-child {
            border-bottom-right-radius: .625rem;
          }
        }
      }
      &:hover {
        td {
          background-color: $gray-sky;
        }
        .table-actions {
          visibility: visible;
        }
      }
    }
    td {
      background-color: $white;
      vertical-align: middle;
    }
  }

  .sortable {
    cursor: pointer;
  }
}

.react-bootstrap-table-sort-order {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  margin-bottom: 0.25rem;
  .caret {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/caret.svg');
    background-repeat: no-repeat;
  }
  &.dropup {
    .caret {
      background-image: url('../../assets/images/caret-up.svg');
    }
  }
}

.react-bootstrap-table-pagination {
  div:first-child {
    display: none;
  }
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.table-orders {
  th, td {
    padding: 1.25rem;
  }

  .expand-cell-header {
    display: none;
  }

  .expand-cell {
    width: rem(78px);
    cursor: pointer;
  }

  td.reset-expansion-style {
    padding: 0;
  }

  .new-message {
    position: relative;
    font-weight: bold;
    &::before {
      content: '';
      height: 10px;
      width: 10px;
      background-color: $green;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: -1.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .table-expanded-row {
    cursor: pointer;
    td {
      background-color: $gray-sky;
    }
  }

  .order-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.table-components {
  background-color: $gray-sky;
  thead {
    th {
      border-bottom: 1px solid $gray-line;
    }
  }

  .component-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: $border-radius;
  }

  tbody {
    td {
      background-color: $gray-sky;
      cursor: pointer;
    }
  }
}