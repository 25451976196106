.nav-tabs {
  border-bottom: none;
  .nav-link {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: rem(18px);
    font-weight: 800;
    border: none;
    padding: 0 0 20px 0;
    margin-right: 70px;
    color: $gray-dark;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background-color: transparent;
      border-bottom: 3px solid $orange;
    }
  }
}