.page-component {

  .select-status {
    width: 220px;
  }

  .width-auto {
    width: auto;
  }

  .component-preview-image {
    border-radius: $border-radius;
  }

  .list-group {
    border-radius: rem(10px);

    &-item {
      display: flex;
      align-items: center;
      min-height: rem(72px);
      border: none;
      border-bottom: 1px solid $gray-line;
      background-image: url('../../assets/images/document.svg');
      background-position: 1rem center;
      background-repeat: no-repeat;
      padding-left: rem(60px);

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }
  }


  .download-file {
    justify-content: space-between;

    &-link {
      display: none;
    }

    &:hover {
      background-color: $gray-sky;

      .download-file-link {
        display: inline;
      }
    }
  }

  .message-form {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    textarea {
      padding: rem(20px);

      &:focus {
        box-shadow: none;
      }
    }
  }

  .info {
    .avatar-image {
      width: 100px;
      height: auto;
      max-height: 100px;
    }
  }

  .messages {
    .avatar {
      min-width: 80px;
      width: 80px;
      height: 80px;
    }

    .form-action {
      border-radius: 0 0 10px 10px;
    }

    .message-content {
      position: relative;
      flex: 1 1 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: $border-radius;

      &:after {
        content: "";
        position: absolute;
        left: -24px;
        top: 24px;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-right: 24px solid $white;
        border-bottom: 16px solid transparent;
      }
    }

    .message-content-dentist {
      position: relative;
      flex: 1 1 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: $border-radius;

      &:after {
        content: "";
        position: absolute;
        left: -24px;
        top: 24px;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-right: 24px solid $lightgreen;
        border-bottom: 16px solid transparent;
      }
    }
  }
}