.page-contract-type {

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    a {
      color: white;
    }
  }
}
.btn-header {
  width: 290px !important;
}