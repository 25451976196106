.page {
  flex: 1;
  background-color: $gray-baby;

  &-light {
    background-color: $gray-100;
  }

  p {
    margin-bottom: 2rem;
  }
}