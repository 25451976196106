.iframe-height {
    height: calc(100vh - 290px);
    width: 100%;
}

.iframe-div-width {
   width: 100%;
   margin-right: 270px;
}

.content-header {
    color: #000;
    padding: 0;
    font-size: 14px;
    width: 100%;
    margin-left: 285px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: .5em 0 0 0;
    flex-grow: 1;
    flex-shrink: 1
}

.lang-select {
    width: 100px;
    margin-right: 270px;
}

.lang-select  {
    .select__control
    {
        min-height: 20px !important;
    }
}

.consent-image {
    margin: 0px 10px;
    max-width: 15px;
    max-height: 15px;

}

.policy-public-page {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    background: url('../../assets/images/bg_network.jpg') center/cover no-repeat;

    &-text {
        text-align: justify;
    }

    @media only screen and (min-width: 1600px) {
        &-col {
            margin-right: 16%;
        }
        &-heading {
            margin-left: 270px;
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        &-col {
            margin-right: 15%;
        }
        &-heading {
            margin-left: 270px;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &-col {
            margin-left: 170px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
       
        &-col-sm {
            padding-left: 50px;
        }
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
        
        &-col-sm {
            padding-left: 50px;
        }
    }
}