.page-users {
  .select-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  .width-auto {
    @include media-breakpoint-up(md) {
      min-width: 200px;
    }

    width: auto;
  }

  .avatar {
    width: 32px;
    height: 32px;
  }
}

.user-dialog {
  .select-container {
    svg {
      position: inherit;
    }
  }
}