.page-client {
  .label {
    width: 180px;
    font-size: rem(13px);
    line-height: 2rem;
    margin-right: 1rem;
    color: $gray;
    letter-spacing: 1px;
  }

  .value {
    font-size: rem(17px);
    font-weight: 600;
    line-height: rem(27px);
  }

  .avatar-image {
    width: 120px;
    height: 120px;
  }
}