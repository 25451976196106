.company-detail-form {
  .form-group {
    svg {
      position: inherit;
    }
  }

  .select-container {
    width: 100%;
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }
}
