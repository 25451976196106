.sidebar {
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  top: 72px;
  bottom: 0;
  z-index: 1;

  &-link {
    display: flex;
    padding: 1.5rem;
    svg {
      fill: $gray-800;
    }
    &:hover, &.active {
      background-color: $primary;
      svg {
        fill: white;
      }
    }
  }
}