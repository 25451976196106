html,
body {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.label {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.label-small {
  font-weight: bold;
  font-size: rem(13px);
  line-height: 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $gray;
}

.label-big {
  font-weight: 800;
  font-size: rem(18px);
  line-height: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $gray;
}

.value {
  font-weight: 600;
  font-size: rem(21px);
  line-height: rem(27px);
  color: $gray-dark;
}

.error-border {
  border: 1px solid red;
}

.align-center {
  align-items: center;
}

.blink {
  color: red;
  margin-right: 0.5rem;
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;

  &:hover {
    animation: none;
    -webkit-animation: none;
  }
}

.width-auto {
  width: auto;
}

.btn-circle-sm {
  width: 22px;
  height: 22px;
  padding: 0px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.no-padding {
  padding: 0;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}