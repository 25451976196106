.dropdown-menu {
  min-width: 200px;
  border: none;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px !important;
  left: 50%;
  transform: translateX(-50%);
  .arrow {
    overflow: hidden;
    position: absolute;
    height: 28px;
    width: 28px;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    &:before {
      content: "";
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      background-color: white;
      transform: rotate(-45deg);
      top: 6px;
      left: 4px;
      box-shadow: 2px -2px 4px -2px rgba(0, 0, 0, 0.1);
    }
    &.right {
      left: auto;
      right: 12px;
    }
  }
}

.dropdown-item {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: $body-color;
  &:hover, &:focus {
    color: $primary;
    background-color: $gray-sky;
  }
}

.dropdown-toggle {
  &::after {
    border: none;
    background-image: url('../../assets/images/dropdown.svg');
    margin-left: rem(12px);
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }
}

.language-dropdown {
  position: relative;
  padding: rem(10px) 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 1.5rem;
    width: 1px;
    transform: translateY(-50%);
    background-color: $gray-line;
  }
  .dropdown-toggle {
    display: flex;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    right: -8px;
    left: auto;
    transform: none;
  }
  .dropdown-item {
    &.active {
      color: $body-color;
      background-color: transparent;
    }
  }
}

.user-dropdown {
  &.has-avatar {
    .dropdown-toggle {
      padding: 0 20px;
    }
  }
}