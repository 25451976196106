.search-wrapper {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 270px;
  }
}

.search-input {
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: rem(12px);
    transform: translateY(-50%);
  }
  .form-control {
    padding-left: 3rem;
  }
}