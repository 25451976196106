.image-upload {
  display: inline-block;
  &-image {
    position: relative;
    cursor: pointer;
    background-color: $white;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .button {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: $white;
      border: 1px solid $gray-line;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: static;
      }
    }
  }
}


.image-upload-control {
  width: 100%;
  position: relative;
  cursor: pointer;

  .img-upload-btn {
    width: auto !important;
    height: 32px;
    line-height: rem(16px);
    font-size: 12px;
    font-weight: 800;
    letter-spacing: rem(1px);
    text-align: center;
  }

  .input-text {
    position: absolute;
    width: 100%;
  }

  .form-control {
    cursor: pointer;
  }

  .browse-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    bottom: 8px;
  }
}

