.page-account {
  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  #account-tabpane-settings {
    .btn {
      padding-left: rem(10px);
      padding-right: rem(10px);
      width: auto;
    }
  }

  .select-wrapper {
    width: 180px;
  }

  .form-company {
    h4 {
      margin-bottom: rem(18px);
    }
    .form-group {
      display: flex;
      align-items: center;
      .form-label {
        min-width: 190px;
      }
    }
  }
}