.switch {
  position: relative;
  &-label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 28px;
    z-index: 1;
    color: white;
    text-transform: uppercase;
    font-size: rem(11px);
    line-height: rem(24px);
    &.active {
      left: rem(14px);
    }
  }
}

.custom-switch {
  margin-bottom: 1rem;
  padding-left: $custom-switch-width + rem(8px);

  .custom-control-label {
    font-weight: 600;
    font-size: rem(21px);
    line-height: rem(24px);
    color: $gray-dark;
    padding-left: rem(12px);

    &:before {
      height: 1.5rem;
      background-color: $gray-line;
      border: none;
    }
    &:after {
      top: rem(4px);
      background-color: $white;
      left: -$custom-switch-width - rem(4px);
    }
  }

  .custom-control-input {
    &:focus {
      ~ .custom-control-label {
        &:before {
          box-shadow: none;
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        &:before {
          background-color: $primary;
        }
        &:after {
          left: -$custom-switch-width - rem(12px);
          transform: translateX($custom-switch-width - 1rem);
        }
      }
    }
  }
}