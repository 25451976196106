.svg-item {
    width: 50px;
    height: 50px;
    font-size: 16px;
    margin: 0 auto;
    animation: donutfade 1s;
    border: 1px solid $primary;
    border-radius: 50%;

    svg {
        max-height: 50px;
    }
}

@keyframes donutfade {

    /* this applies to the whole svg item wrapper */
    0% {
        opacity: .2;
    }

    100% {
        opacity: 1;
    }
}


.donut-ring {
    stroke: #EBEBEB;
}

.donut-segment {
    transform-origin: center;
    stroke: #FF6200;
}

.donut-segment-2 {
    stroke: $primary;
    animation: donut1 3s;
}

.segment-1 {
    fill: #ccc;
}



.donut-percent {
    animation: donutfadelong 1s;
}

@keyframes donutfadelong {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.donut-text-1 {
    fill: $primary;
}

.donut-label {
    font-size: 0.28em;
    font-weight: 700;
    line-height: 1;
    fill: #000;
    transform: translateY(0.25em);
}

.donut-percent {
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
}

.donut-data {
    font-size: 0.12em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color: #666;
    fill: #666;
    animation: donutfadelong 1s;
}