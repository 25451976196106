.landing-page-form {
  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  .btn-outline-primary {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 280px;
    }
  }

  .browse-input {
    width: 45%;
  }

  .width-auto {
    width: auto;
  }
}

#preview-modal {
  max-width: 100%;
  min-height: 100%;
  margin: 0;

  .modal-content {
    max-height: 100%;
  }

  .preview-close {
    display: flex;
    justify-content: flex-end;
  }
}