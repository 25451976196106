// Color system
$white: #fff;
$gray-100: #F7F9F9;
$gray-800: #3A3F4B;

$gray: #798795;
$gray-sky: #F7F8F9;
$gray-baby: #EAEEEF;
$gray-light: #A7B5C3;
$gray-dark: #393F4B;
$gray-line: #C7CED8;

$orange: #FA9600;
$green: #37DF29;
$black: #2E323B;
$lightgreen: #F7FFF6;

$primary: $orange;
$body-color: $gray-800;

$gray-bright: #DEE4E8;

$colors: (
  "gray": $gray,
  "gray-sky": $gray-sky,
  "gray-baby": $gray-baby,
  "gray-light":  $gray-light,
  "gray-dark":  $gray-dark,
  "gray-bright": $gray-bright,
  "gray-line":  $gray-line,
  "lightgreen": $lightgreen,
);

// Buttons + Forms
$input-btn-font-size:         .875rem;
$input-btn-font-size-sm:      .75rem;
$input-btn-font-size-lg:      .875rem;

$input-btn-padding-y:         .5625rem;
$input-btn-padding-y-sm:      .1875rem;
$input-btn-padding-y-lg:      .8125rem;

$input-btn-line-height:       2;
$input-btn-line-height-sm:    2;
$input-btn-line-height-lg:    2;

$btn-disabled-opacity: 1;

$spacers: ();
@for $i from 0 through 80 {
  $spacers: map-merge($spacers, (
    ($i*2): #{$i * .125}rem
  ));
}

// Typography
$font-weight-normal: 600;
$font-size-base: 1.0625rem;
$font-family-sans-serif: 'Mulish', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: 'Poppins';
$headings-font-weight: bold;

$headings-margin-bottom: 0;

$font-weight-bolder: bold;

// Modals

$modal-content-border-radius: 0;
$modal-inner-padding: 0;

$modal-header-padding-y: 0;
$modal-header-padding-x: 0;

// Forms
$label-margin-bottom: .25rem;

$input-color: $gray-800;
$input-padding-y: .625rem;
$input-padding-x: 1rem;
$input-line-height: 1.5882352941;

$input-placeholder-color: $gray-light;

$custom-switch-width: 3.75rem;
$custom-switch-indicator-border-radius: 3.125rem;
$custom-switch-indicator-size: 1rem;

// Tables
$table-cell-padding: 1rem;
$table-head-color: $gray;
$table-border-color: $gray-line;
$table-th-font-weight: bold;

// Components
$border-radius: .3125rem !default;
$border-color: $gray-line !default;

// List group
$list-group-item-padding-y: 1.375rem;

// Toasts
$toast-header-color: $white;
$toast-header-background-color: $black;
$toast-color: $white;
$toast-background-color: $black;
$toast-border-radius: $border-radius;
$toast-border-width: 0;

// Modals
$modal-lg: 600px !default;
$modal-md: 500px !default;
$modal-sm: 400px !default;