@function rem($size) {
  @return $size / 16px * 1rem;
}

$min: 0;
$max: 60;
$increment: 0.125; // 2px

// Fonts
@for $i from $min through $max {
  .fs-#{$i*2} {
    font-size: #{$i * $increment}rem;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from $min through $max {
      .fs-#{$breakpoint}-#{$i*2} {
        font-size: #{$i * $increment}rem;
      }
    }
  }
}

// Line heights
@for $i from $min through $max {
  .lh-#{$i*2} {
    line-height: #{$i * $increment}rem;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from $min through $max {
      .lh-#{$breakpoint}-#{$i*2} {
        line-height: #{$i * $increment}rem;
      }
    }
  }
}

// font weights
$font-weights: 400, 500, 600, 700, 800, 900;
@each $font-weight in $font-weights {
  .fw-#{$font-weight} {
    font-weight: #{$font-weight};
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $font-weight in $font-weights {
      .fw-#{$breakpoint}-#{$font-weight} {
        font-weight: #{$font-weight};
      }
    }
  }
}

// Colors
@each $color, $value in $colors {
  .color-#{$color} {
    color: $value;
  }
  .bg-#{$color} {
    background-color: $value;
  }
}

// Spinner CSS
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid $primary;
  border-color: $primary transparent $primary transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

// END CSS Spinner