.form-lab-contact {
  .form-group {
        svg {
            position: inherit;
        }
    }

    .select-container {
        width: 35%;
    }

    .msg-text-area {
        height: 240px;
    }

    .disable-resize {
        resize: none;
    }

    .btn {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 200px;
        }
  }
}
