.public-footer {
    &-container {
        max-width: 100%;
    }

    &-image {
        max-height: 80px;
    }

    @media only screen and (min-width: 1200px) {
        &-logo {
            margin-left: 240px;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &-logo {
            margin-left: 127px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        &-logo {
            margin-left: 60px;
        }
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
        &-logo {
            margin-left: 10px;
        }
    }
}