.search-bar {
  width: 40%;
  margin-right: 20px;
}

.search-text {
  font-size: 0.75rem;
}

.div-tabs {
  padding: 0px;
}

.div-tabs .container-fluid {
  padding: 0px;
}

.div-tabs .nav-link {
  font-size: 12px !important;
  padding: 0 0 10px 0;
  margin-right: 25px;
}

.license-content-table-row {
  cursor: pointer;
}

.license-content-table-row td {
  background-color: #fff9f1 !important;
}

.license-page-container {
  margin: 0;
  height: calc(100vh - 100px) !important;
}

.search-input {
  margin-right: 20px;
}

.search-btn {
  width: 100%;
}

.mr-row {
  margin-right: 20%;
}

.div-border {
  border: 1px solid $gray-line;
  background-color: $white;
}

.license-nodata-row {
  height: calc(100vh-480px);
}

.license-bottom-row {
  height: calc(100vh - 420px);
}

.table-wrapper {
  max-height: calc(100vh - 380px);
  overflow: auto;
  width: 100%;
  display: inline-block;
}

.table-wrapper thead {
  position: sticky;
  top: 0px;
  background-color: white;
  margin: 0 0 0 0;
}

.license-public-page {
  width: 99vw;
  display: flex;

  &-text {
    text-align: justify;
  }

  @media only screen and (min-width: 1200px) {
    &-col {
      margin: 0 285px;
      padding: 0;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    &-col {
      margin: 0 170px;
      padding: 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    &-col {
      margin: 0 100px;
      padding: 0;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    &-col {
      margin: 0 50px;
      padding: 0;
    }
  }
}
