.toast {
  position: fixed;
  top: rem(92px);
  right: rem(20px);
  z-index: 1051;
  min-width: 250px;
  padding: 1.5rem;
  cursor: pointer;
}

.toast-header {
  position: relative;
  padding-top: 0.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 0 rem(10px) 0;

  .close {
    display: none;
  }
}

.toast-body {
  padding: 0;
}