.page-clients {
  .select-wrapper {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .table {
    tbody {
      td {
        cursor: pointer;
      }
    }
  }
}